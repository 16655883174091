import React from "react";

export const Form = ({
  setInputText,
  todos,
  setTodos,
  inputText,
  setStatus,
}) => {
  const inputTextHandler = (e) => {
    let currInputText = e.target.value;
    setInputText(currInputText);
  };
  const submitTodoHandler = (e) => {
    e.preventDefault();
    setTodos([
      ...todos,
      { text: inputText, completed: false, id: Math.random() * 1000 },
    ]);
    setInputText("");
  };
  const statusHandler = (e) => {
    setStatus(e.target.value);
  };
  return (
    <div className="wrapper">
      <form>
        <div className="input-container input-container--left">
          <input
            value={inputText}
            onChange={inputTextHandler}
            type="text"
            className="todo-input"
          />
          <button
            onClick={submitTodoHandler}
            className="todo-button"
            type="submit"
          >
            <i className="fas fa-plus-square input-icon"></i>
          </button>
        </div>
        <div className="input-container input-container--right">
          <div className="select">
            <select
              onChange={statusHandler}
              name="todos"
              className="filter-todo"
            >
              <option value="all">All</option>
              <option value="completed">Completed</option>
              <option value="uncompleted">Uncompleted</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  );
};
